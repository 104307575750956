// @flow
import {graphql} from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Image from '../components/Image';
import Layout from '../components/Layout';
import Markdown from '../components/Markdown';
import Row from '../components/Row';
import Section from '../components/Section';
import SectionSingleColumn from '../components/SectionSingleColumn';
import strings from '../config/strings.json';
import {mediaQueries} from '../theme';
import {LocationType} from '../types';
import {rhythm} from '../utils/typography';

const StyledImage = styled(Image)`
  display: block;
  margin-bottom: ${rhythm(1 / 2)};
  ${mediaQueries.MIN_MD} {
    margin-bottom: ${rhythm(1)};
  }
  ${mediaQueries.MIN_LG} {
    margin-bottom: ${rhythm(2)};
  }
`;

class About extends React.Component {
  render() {
    const {data, location} = this.props;
    const {header, content, pageMeta} = strings.pages['about'];
    const {pathname: path} = location;

    return (
      <Layout path={path} pageMeta={{strings: pageMeta}}>
        <Section>
          <Row justifyCenter>
            <StyledImage
              // This image is retrieved directly from DatoCMS using the GraphQL query below
              imageSource={data.dato.upload}
              imageModifiers={{
                width: 1000,
                hideBlurhash: true,
              }}
            />
          </Row>
        </Section>
        <SectionSingleColumn header={header}>
          <Markdown content={content} />
        </SectionSingleColumn>
      </Layout>
    );
  }
}

About.propTypes = {
  location: LocationType,
};

export default About;

export const query = graphql`
  query AboutImageQuery {
    dato {
      upload(filter: {id: {eq: "10326964"}}) {
        ...imageFields
      }
    }
  }
`;
