// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import dimensions from '../theme/dimensions';
import {ChildrenType} from '../types';

import Col from './Col';
import Row from './Row';

const StyledContentWrapper = styled.div`
  max-width: ${dimensions.contentWidth.medium}px;
  margin: 0 auto;
`;

class SectionSingleColumn extends React.Component {
  render() {
    const {children, header, subheader} = this.props;

    // TODO: Should the header not be an h1 tag, even if we style it differently?
    return (
      <Row justifyCenter>
        <Col md={6} xl={5}>
          <StyledContentWrapper>
            {header && <h3 data-testid="title">{header}</h3>}
            {subheader && <h4 data-testid="subtitle">{subheader}</h4>}
            <div className="content">{children && children}</div>
          </StyledContentWrapper>
        </Col>
      </Row>
    );
  }
}

SectionSingleColumn.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  children: ChildrenType,
};

export default SectionSingleColumn;
